import 'magnific-popup';

$(`[data-lightbox='roles']`).magnificPopup({
    delegate: 'a', // child items selector, by clicking on it popup will open
    modal: true, // When set to true, the popup will have a modal-like behavior: it won’t be possible to dismiss it by usual means (close button, escape key, or clicking in the overlay).
    // other options
    gallery:{
        enabled: true,
        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button
        tPrev: 'Previous (Left arrow key)', // title for left button
        tNext: 'Next (Right arrow key)', // title for right button
    }
});