import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

$(document).ready(function(){

    $(`[data-slick='hero']`).slick({
        dots: false,
        arrows: false,
        infinite: true,
        fade: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
    });

    $(`[data-slick='core']`).slick({
        dots: true,
        arrows: true,
        slidesToShow: 1,
        autoplay: true,
        pauseOnHover: true,
        pauseOnFocus: false,
        mobileFirst: true,
        // adaptiveHeight: true,
        // prevArrow: $(`[data-slick-nav='carousel'] button:first-child`),
        // nextArrow: $(`[data-slick-nav='carousel'] button:last-child`),
    });
    
    $(`[data-slick='collage-1']`).slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 9000,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
    });
    $(`[data-slick='collage-6']`).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 9000,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        dots: true,
        arrows: true,
        mobileFirst: true,
    });

    $(`[data-slick='collage-2']`).slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
    });

    $(`[data-slick='collage-3']`).slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
    });

    $(`[data-slick='collage-4']`).slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 9000,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
    });

    $(`[data-slick='collage-5']`).slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
    });

    $(`[data-slick='roles']`).slick({
        dots: true,
        arrows: true,
        slidesToShow: 1,
        autoplay: true,
        pauseOnHover: true,
        pauseOnFocus: false, 
        // adaptiveHeight: true,
        // prevArrow: $(`[data-slick-nav='carousel'] button:first-child`),
        // nextArrow: $(`[data-slick-nav='carousel'] button:last-child`),
    });

    $(`[data-slick='videos']`).slick({
        dots: true,
        arrow: false,
        centerMode: true,
        slidesToShow: 3,
        autoplay: true,
        pauseOnHover: true,
        pauseOnFocus: false,
        // prevArrow: $(`[data-slick-nav='videos'] button:first-child`),
        // nextArrow: $(`[data-slick-nav='videos'] button:last-child`),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

});