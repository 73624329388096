// jQuery for page scrolling feature - requires jQuery Easing plugin
import 'jquery.easing/jquery.easing';

$(function() {
    $(document).on('click', 'button#revise-jobs', function(event) {
        $('html, body').stop().animate({
            scrollTop: $('body').offset().top
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });
});