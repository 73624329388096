document.querySelectorAll(".burger-menu").forEach(el => {
    const toggleMenu = () => {
        el.classList.toggle("active");
        document.querySelector(".nav-menu").classList.toggle("active");
        document.querySelector("body").classList.toggle("fixed");
    };
    el.addEventListener("click", toggleMenu);

    el.addEventListener("keydown", e => {
        if (e.key === "Enter") {
            toggleMenu();
        }
    });

    const menuItems = document.querySelectorAll(".nav-menu a");
    const lastMenuItem = menuItems[menuItems.length - 1];

    lastMenuItem.addEventListener("blur", () => {
        el.classList.remove("active");
        document.querySelector(".nav-menu").classList.remove("active");
        document.querySelector("body").classList.remove("fixed");
    });

})
