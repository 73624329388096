function success(e) {
    $('#coordinates').val(e.coords.latitude + ',' + e.coords.longitude);
    $('#coordinates').closest('form').submit();
}

function error(e) {
    // console.log(e);
}
function accessLocation() {
    navigator.geolocation && navigator.geolocation.getCurrentPosition(success, error)
}

$(document).ready(function() {
    $("#rungeo").click(function() {
        accessLocation()
    });
})