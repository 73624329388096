import axios from "axios";
import typeahead from "typeahead-standalone";

document.querySelectorAll(".store-positions-info, .job-requisition").forEach(el => {
    el.addEventListener("click", e => {
        dataLayer.push({ event: "view-job-click", category: "applicant-journey", action: "click", label: "view-job-details" });
    })
})

document.querySelectorAll(".Hero__form__button").forEach(el => {
    el.addEventListener("click", e => {
        const event = (document.querySelector(".page-home")) ? "find-job-click" : "search-job-click"
        dataLayer.push({ event, category: "applicant-journey", action: "click", label: "find-jobs" });
    })
})

document.querySelectorAll(".JobDetails__apply").forEach(el => {
    el.addEventListener("click", e => {
        dataLayer.push({ event: "apply-job-click", category: "applicant-journey", action: "click", label: "apply-job" });
    })
})

/* Job Requisition Search */
document.querySelectorAll("#search_category").forEach(categories => {
    const functions = document.getElementById("search_function")
    const zipCode = document.getElementById("searchlocation")
    const assertZipCode = () => {
        zipCode.disabled = false
        if (categories.value === "Support Center" || functions.value !== "Panda Restaurant Group") {
            zipCode.value = ""
            zipCode.disabled = true
        }
    }
    let selectedFunction = null
    const params = new URLSearchParams(window.location.search);
    categories.addEventListener("change", async e => {
        const response = await axios.get(`jobs/list-functions/${e.target.value}`)
        for (let i = functions.options.length - 1; i >= 0 ; i--) functions.options.remove(i)
        response.data.forEach(opt => {
            const el = document.createElement('option');
            el.value = opt;
            el.innerHTML = opt;
            functions.appendChild(el);
        })
        functions.value = selectedFunction ?? "Panda Restaurant Group"
        selectedFunction = null
        assertZipCode()
    })
    functions.addEventListener("change", assertZipCode)
    if (params.has("category")) {
        categories.value = params.get("category")
        // noinspection JSCheckFunctionSignatures
        categories.dispatchEvent(new Event('change'))
        if (params.has("jobfunction")) selectedFunction = params.get("jobfunction")
    }
})

document.querySelectorAll("#load-more-jobs").forEach(button => {
    button.addEventListener("click", async e => {
        const res = await axios.get("/jobs/load-more")
        if (!res.data) return document.getElementById("load-more-jobs").remove()
        document.getElementById("job-list").innerHTML += res.data
    })
})


// location name search code
const storeNameInput = document.getElementById("storename");
const storeDataInput = document.getElementById("storedata-hidden");
if (storeNameInput) {
    const instance = typeahead({
        input: storeNameInput,
        minLength: 2,
        highlight: false,
        hint: false,
        limit: 10000,
        source: {
            prefetch: {
                url: '/jobs/stores',
                when: 'onFocus',
            },
            keys: ["name"],
        },
        display: (item, event) => {
            if (event) {
                storeDataInput.value = `${item.id}|${item.name}`;
            }
            return item.name;
        },
    });

    storeNameInput.addEventListener('input', (e) => {
        if (e.target.value === '') {
            storeDataInput.value = '';
        }
    });

}

// rewards page
const totalRewardsPerks = document.querySelectorAll('.page-total-rewards .Reward-Perks__item')
const totalRewardsContainer = document.querySelector('.page-total-rewards');
const rewardHeaders = document.querySelectorAll('.Reward-Perks__item__header');

function handleKeydown(event) {
    if (event.key === 'Enter') {
        event.target.click();
    }
}
function addRewardsActions(){
    rewardHeaders.forEach(header => {
        if(header.classList.contains('Reward-Perks__item__header__last')) return;
        const title = header.querySelector('.Reward-Perks__item__title').innerText;
        header.setAttribute('aria-label', `Tap to view benefits: ${title.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}`);
        header.setAttribute('tabindex', `0`);
        header.addEventListener('keydown', handleKeydown);
    });
}
function removeRewardsActions() {
    rewardHeaders.forEach(header => {
        header.removeAttribute('aria-label');
        header.removeAttribute('tabindex');
        header.removeEventListener('keydown', handleKeydown);
    });
}
function handleResize() {
    if (window.innerWidth <= 1200) {
        addRewardsActions();
    } else {
        removeRewardsActions();
    }
}
document.addEventListener("DOMContentLoaded", function() {
    if(totalRewardsContainer){
        totalRewardsPerks.forEach((el) => {
            el.addEventListener('click', e => {
                e.stopPropagation();
                totalRewardsPerks.forEach(item => {
                    if (item !== el) {
                        item.classList.remove('active');
                    }
                });
                el.classList.toggle('active');
            });
        })
        document.body.addEventListener('click', e => {
            totalRewardsPerks.forEach(activeEl => {
                activeEl.classList.remove('active');
            });
        });
        handleResize();
        window.addEventListener('resize', handleResize);
    }
});
