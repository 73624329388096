$(document).ready(function () {

    // * get total number of posts
    let totalPosts = $("#results-table tr").length;
    // console.log(totalPosts);

    // * display by default
    let x = 10;
    
    $('#results-table tr').hide(); // hide on load
    $('#results-table tr:lt('+x+')').show(); // show the first x 

    $('[data-load-jobs]').click(function(e) {
        e.preventDefault();

        x = (x + 5 <= totalPosts) ? x + 5 : totalPosts;
        $('#results-table tr:lt('+x+')').show();

        // * hide button when all posts are displayed
        if(x === totalPosts) {
            $(this).hide();
        };
    });

});