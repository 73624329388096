window.addEventListener("load", (event) => {
    Array.from(document.getElementsByClassName("multiselect")).forEach(ms => {
        const select = ms.querySelector("select")
        const input = ms.querySelector("input")
        const pane = ms.querySelector(".select-pane");
        const update = () => {
            const selected = Array.from(select.querySelectorAll("option")).filter(o => o.selected)
            input.value = (selected.length === 0) ? "" : (selected.length === 1) ? selected[0].text : "Multiple stores"
        }

        input.addEventListener("mousedown", e => pane.classList.toggle("active"))
        document.addEventListener("mousedown", e => {
            console.log(e.target.closest(".multiselect"))
            e.target.closest(".multiselect") || pane.classList.toggle("active", false)
        })

        ms.querySelectorAll(".selectable").forEach(c => {
            c.addEventListener("click", e => {
                select.querySelector(`option[value='${c.dataset.store}']`).selected = c.classList.toggle("active")
                update()
            })
        })
        select.querySelectorAll("option").forEach(op => {
            op.addEventListener("click", e => {
                select.querySelectorAll("option").forEach(o => {
                    ms.querySelector(`[data-store='${o.value}']`).classList.toggle("active", o.selected)
                })
                update()
            })
        })
    })
})